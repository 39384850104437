import { useEffect } from 'react';
import { useAtomMutator } from '@konsus/atoms';
import { pageVibeDarkMode } from '@konsus/lego/src/utils';
import { SpecialBackgrounds, type Theme } from '@superside/ui';
import { type VibeSwitchType } from '@konsus/lego/src/blocks/VibeSwitch/types';
import { useVibes } from './useVibesOnPage';

export const darkModesByTheme: { [key in Theme]: boolean } = {
  aurora: true,
  superads: false,
  marble: false
};

export const getDarkModeByTheme = (theme: Theme) => darkModesByTheme[theme];

export const useTheme = ({
  theme,
  darkMode,
  specialBackground = SpecialBackgrounds.stars
}: VibeSwitchType) => {
  useVibes({ theme, darkMode });
  const setPageVibe = useAtomMutator(pageVibeDarkMode);
  const darkModeWithDefault = darkMode ?? getDarkModeByTheme(theme) ?? true;

  useEffect(() => {
    if (theme) {
      setPageVibe(darkModeWithDefault);
      document.body.dataset.theme = theme;

      document.body.style.setProperty('--bor-special-background', specialBackground);
    }
  }, [darkModeWithDefault, setPageVibe, specialBackground, theme]);
};
