import React from 'react';
import { VisualAsset, type VisualAssetProps } from '../../components/VisualAsset';
import { PageSection } from '../../components/PageSection';

export interface VisualAssetSectionProps {
  asset: VisualAssetProps;
}

export const VisualAssetSection = (props: VisualAssetSectionProps) => {
  const { asset, ...rest } = props;

  return (
    <PageSection {...rest}>
      <VisualAsset className='rounded-lg' {...asset} />
    </PageSection>
  );
};

export default VisualAssetSection;
