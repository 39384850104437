import React from 'react';
import { cn } from '@superside/ui';
import theme from '../../theme/theme';
import { normalizeColor } from '../../utils';

export const Icon = (props) => {
  const { icon: IconComponent, size = 'small', color = 'currentColor', className, ...rest } = props;

  const iconSize =
    typeof size === 'number'
      ? theme.space[size]
      : size in theme.icon.size
      ? theme.icon.size[size]
      : size;

  return (
    <IconComponent
      size={iconSize}
      color={normalizeColor(color, theme)}
      className={cn(className, 'flex-none')}
      {...rest}
    />
  );
};
