import { useEffect, useRef } from 'react';
import { theme } from '@konsus/superside-kit';
import { type Theme } from '@superside/ui';
import { useAtomMutator } from '@konsus/atoms';
import { pageVibes } from '@konsus/lego/src/utils';
import { type VibeSwitchType } from '@konsus/lego/src/blocks/VibeSwitch/types';

const debounce = (func: () => void, wait: number) => {
  let timeout: NodeJS.Timeout;

  return (...args: any) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
};

export const useVibes = (defaultTheme: VibeSwitchType) => {
  const setVibesOnPage = useAtomMutator(pageVibes);
  const previousVibesRef = useRef<any[]>([]);

  useEffect(() => {
    const setVibes = () => {
      const vibes = document.querySelectorAll('[data-id="vibeSwitch"]');
      const offsetTops = [
        { offsetTop: 0, ...defaultTheme },
        ...Array.from(vibes).map((vibe) => {
          const htmlElement = vibe as HTMLElement; // Safe cast to HTMLElement

          return {
            offsetTop: htmlElement.offsetTop,
            theme: (htmlElement.getAttribute('data-theme') as Theme) ?? theme,
            darkMode: htmlElement.getAttribute('data-darkMode') === 'true',
            hex: htmlElement.getAttribute('data-hex')
          };
        })
      ].filter(Boolean);

      if (JSON.stringify(offsetTops) !== JSON.stringify(previousVibesRef.current)) {
        setVibesOnPage(offsetTops);
        previousVibesRef.current = offsetTops;
      }
    };

    const debouncedSetVibes = debounce(setVibes, 200);

    setVibes();

    window.addEventListener('scroll', debouncedSetVibes);

    return () => window.removeEventListener('scroll', debouncedSetVibes);
  }, [defaultTheme, setVibesOnPage]);
};
